#logo{
    display: block;
    width: 100%;
    transform: translateX(-50%);
    opacity: 0;
    animation: animateLogo 2s forwards;
}
.letter1{
    display:inline;
    fill:#012e43;
    fill-opacity:1
}
@keyframes animateLogo{
    to{
        transform: translateX(0%);
        opacity: 1;
    }
}
#circleFirst{
    animation: animateTron 3s forwards;
    opacity: 0;
    animation-delay: 1s;
}
#circleSecond{
    animation: animateTron 3s forwards;
    opacity: 0;
    animation-delay: 2s;
}
.letterTron{
    animation: animateTron 3.4s forwards;
    opacity: 0;
}
@keyframes animateTron{
    to{
        opacity: 1;
    }
}
.btn-logo{
    position: relative;
    width: 240px;
    margin-left: 1rem;
    transition: 0.2s;
}
.btn-login{
    display: block;
    background-color: transparent;
    color: #054261;
    font-size: 0.8rem;
    font-family: Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-transform: uppercase;
    border-radius: 10px;
    padding: 0.5rem 1.2rem;
    border: 2px solid;
    border-color: white #054261 #054261 white;
    height: 45px;
    line-height: 25px;
    transition: 0.2s;
}
.btn-menu{
    display: none;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #054261;
    font-size: 3.6rem;
    padding: 0rem;
    height: 45px;
    line-height: 25px;
    border-radius: 10px;
    border: none;
    transition: 0.2s;
}
.btn-menu:hover{
    color: #054261;
    transition: 0.2s;
}
.btn-login:hover{
    border-top: 2px solid #054261;
    border-left: 2px solid #054261;
    transition: 0.2s;
}
@media screen and (max-width: 991px){
    .btn-menu{
        display: flex;
    }
}
@media screen and (max-width: 750px){
    .btn-login{
        display: none;
    }
}
@media screen and (max-width: 600px){
    .btn-menu{
        font-size: 2.8rem;
    }
}