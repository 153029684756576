#card-title{
    font-family:'Rajdhani', sans-serif;
    font-size: 1.8rem;
    color: white;
    line-height: 2.2rem;
    letter-spacing: 0.1rem;
}
#card-button:hover{
    background-color: white;
    color: blue;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    cursor: pointer;
    transition: 0.4s;
}