.slider{
    background-image: linear-gradient(rgba(0, 0, 0, 0.801),rgba(0, 0, 0, 0.678));
    height: 40px;
    margin: auto;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 998;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-top: 2px solid white;
}
.slider::before, .slider::after{
    background: linear-gradient(to right,rgba(0, 0, 0, 0.9) 0%, transparent 100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 20%;
    z-index: 2;
}
.slider::before{
    left: 0;
    top: 0;
}
.slider::after{
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}
.slide-track{
    display: flex;
    width: calc(650px * 10);
    animation: scroll 30s linear infinite;
}
.slide-track:hover{
    animation-play-state: paused;
}
@keyframes scroll {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(calc(-650px * 5));
    }
}
.slideInfo{
    width: 650px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
    cursor: pointer;
}
.slideInfo a{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    transition: 0.4s;
    letter-spacing: 2px;
    text-decoration: none;
    font-size: 0.8rem;
}
.slideInfo a:hover{
    color: #054261;
}