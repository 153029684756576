.sidebar {
    width: 100%;
    height: 40px;
}
  
  /* .sidebar ul {
   
  } */
  
  .sidebar li {
    list-style-type: none;
    margin-top: -10px;
    margin-bottom: 30px;
  }
  
  .sidebarbt {
    text-decoration: none;
    color: #333;
  }
  
  .sidebarbt:hover {
    color: #18224d;
    font-weight: bold;

  }

  .backgroundSidebar {
    display: flex;
    width: 100%;
    height: 4rem;
    padding: 20px;
    justify-content:left;
    align-items: center;
    background-color: #ffff;
  }

.backgroundSidebar:hover{
    background-color: #f4f4f4;
}