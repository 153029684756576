#conteiner-equipe{
    display: flex;
    gap: 1rem;
}
#card-equipe{
    position: relative;
    width: 300px;
    height: 540px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px gray;
    overflow: hidden;
    margin-top: 0.3rem;
}
#card-background{
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(rgb(1, 18, 82), rgb(27, 27, 27));
    border-radius: 10px;
    height: 140px;
    width: 100%;
}
#card-image{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 200px;
    width: 200px;
    background-color: rgb(1, 18, 82);
    border: 8px solid rgb(1, 18, 82);
    border-radius: 50%;
    overflow: hidden;
}
#card-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
#card-social{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    border-top: 8px solid rgb(1, 18, 82);
    width: 300px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px gray;
    background-color: white;
}
#card-social a{
    border: 2px solid gray;
    color: gray;
    transition: 0.4s;
}
#card-social a:hover{
    border: 2px solid rgb(1, 18, 82);
    color: rgb(1, 18, 82);
    transition: 0.4s;
}
#card-social a:active{
    border: 2px solid rgb(1, 18, 82);
    color: rgb(1, 18, 82);
    transition: 0.4s;
}
#conteiner-equipe2{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
}
@media screen and (max-width: 800px){
    #conteiner-equipe{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #conteiner-equipe2{
        width: 500px;
        margin: auto
    }
}
@media screen and (max-width: 520px){
    #conteiner-equipe2{
        width: 300px;
    }
}