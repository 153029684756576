.nav-responsive{
    display: flex;
    align-items: center;
    margin-left: 2.6rem;
    font-size: 1rem;
    color: white;
}
.nav-social{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8rem;
    font-size: 1.2rem;
    color: white;
    transition: 0.4s;
    padding-top: 0.5rem;
}

@media screen and (max-width: 900px){
    .footer-responsive{
        flex-direction: column-reverse;
        gap: 1rem;
    }
    .nav-responsive{
        margin-left: 0rem;
        border-top: 1px solid rgb(255, 255, 255);
        padding-top: 1rem;
    }
    .nav-social{
        margin-right: 0rem;
    } 
}
@media screen and (max-width: 520px) {
    .nav-social{
        font-size: 1rem;
        margin-right: auto;
        margin-left: 2rem;
        margin: auto;
    }
    .nav-responsive{
        display: flex;
        gap: 1rem;
        flex-direction: column;
        font-size: 0.8rem;
        margin: auto;
        width: 350px;
    }
}